//Fonts
$font-stack: Roboto, sans-serif;

//Colors
$primary-color: #8C52FF;
$secondary-color: #76CCCF;
$accent-color: #94A0B6;
$pop-color: #FF914D;
$font-color: #313335;

body, html {
  height: 100%;
  font: $font-stack;
  color: $font-color;
}
.max-vh {
  height: 90vh;
}
.root {
  overflow-y: scroll;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.hero {
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
h1 {
  text-shadow: 0 1px 0 $accent-color;
}
h3 {
  text-shadow: 0 1px 0 $accent-color;
}
.title {
  padding: 2em;
  text-transform: uppercase;
  text-align: left;
}
.tp-3 {
  padding-top: 3em;
}
.tb-5 {
  padding-bottom: 5em;
}

nav a:hover {
  color: $primary-color !important;
  transform: scale(1.1); 
}

.fancy-btn {
  padding: 1em;
  -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
  float: right;
}
//wave animation
div.waves{
  width: 100%;
  top: -18%;
  position: absolute;
  z-index: -1;
}
#wave-svg{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation-name: move-left;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: scale(1, -1);
}
.spacer{
  height: 100px;
  width: 100%;
}

@keyframes example {
  0%   {
    left:0px;
    top:0px;}
  
  50%  {
    left:-2000px;
    top:200px;}
  
  100% {
    left:0px; 
    top:0px;}
}


.contact-container {
  position: absolute;
  top: 1%;
  left: 5%;
}

.twitter-embed {
  overflow-y: scroll;
  max-height: 60vh;
}

.text:hover, .text2:hover {
  color: white;
  text-decoration: none;
  transform: scale(1.1);
}
.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 250px;
}
.text{
  position: absolute;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.btn-link {
  text-decoration: none;
}

//social media bar
.social {
  height: 200px;
  width: 50px;
  background: whitesmoke;
  border-radius: 35px;
  margin: 20vh auto;
  position: fixed;
  top: 40%;
  right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  font-size: 1.25em;
  box-shadow: 2px 2px 5px grey;
    a {
      color: $secondary-color;
    }
    a:hover, a:visited {
      color: $primary-color;
      transform: scale(1.1);
    }
}
.social div {
  background-color: whitesmoke;
  .icon-social {
    color: $secondary-color;
    &:hover, :visited, a:hover, a:visited {
      color: $primary-color;
      transform: scale(1.2);
      text-decoration: none;
    }
  }
  margin: 0 auto;
  &:hover {
    transform: scale(1.2);
  }
}
.my-btn {
  background-color: $primary-color !important;
  color: whitesmoke !important;
  padding: 0 10px;
  &:hover {
    transform: scale(1.2);
  }
}

.row-1, .row-2 {
  height: 300px !important;
}
/* resume page styling */
.abt-wrapper {
  position: relative;
}
.abt-text {
  position: absolute;
  display: inline-block;
  top: 5%;
  left: 12%;
}
.abt-img {
  display: inline-block;
  width: 15%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.about {
  width: 80%;
  margin: 0 auto;
}
.resume, .tech {
  text-align: center;
  padding-top: 2em;
  width: 80%;
  margin: 0 auto;
}
.resume a:visited, a:focus, a:active, a{
  color: $accent-color;
  font-weight: 700;
  text-transform: uppercase;
}
.resume a:hover {
  color: whitesmoke ;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-span {
  background-image: linear-gradient(120deg, $primary-color 0%, $secondary-color 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em !important;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88% !important;
  }
}

/* project page styling */

.wrapper {
  justify-content: center;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay2 {
  position: absolute;
  top: 0; 
  bottom: 0; 
  height: 100%;
  width: 100%;
  opacity: 0; 
  background-color:$accent-color;
  transition: .5s ease-in;
}
.wrap {
  position: relative; 
}
.wrap:hover .overlay2 {
  opacity: 1; 
}
.desc{
  color: white !important;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.desc a:visited, .desc a:focus, .desc a {
  color: white !important;
}
.desc a:hover {
  font-weight: 700;
  color: white !important;
}
.projects {
  margin-bottom: 6em;
}
.purple {
  font-weight: 700;
  color: $primary-color;
}
.mint {
  color: $secondary-color;
  font-weight: 700;
}
.orange {
  color: $pop-color;
  font-weight: 700;
}
.msg {
  color: $primary-color;
  font-weight: 700;
  padding-left: 2em;
}

/*HOME IMAGE & MEDIA QUERIES*/
.home-desktop { 
  background-image: url(../src/assets/desktop-1@2x.png);
  background-size: cover;
  background-position:0 10%;
  height: 100vh;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  
}

/* ipad pro 12.9*/
@media (max-width: 1024px) {
  .home-desktop {
    background-image: url(../src/assets/ipad-1.png);
    background-position: center;
  }
  .contact-container {
    top: 5%;
  }
  div.waves {
    top: -4%;
  }

} 
/* Portrait tablet and small desktops*/
@media (max-width: 960px) {
  .home-desktop {
    background-image: url(../src/assets/iPad-small.png);
  }
  div.waves {
    top: -7%;
  }
} 

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
.home-desktop {
  background-image: url(../src/assets/iPad-small.png);
}
.sc-container, .social {
  display: none;
}
div.waves {
  top: -6%;
}
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .abt-text {
    margin-top: 0 !important;
  }
  .resume {
    margin-bottom: 20em;
  }
  section {
    display: none;
  }
  .contact-links {
    margin-left: -2em;
    margin-top: -4em;
  }
  .home-desktop {
    background-image: url(../src/assets/phone.png);
    background-position: right top;
  }
  .mobile {
    margin-left: 1em;
    margin-top: 2em;
  }
  .sc-container, .social, .wave {
    display: none;
}
  div.waves {
    top: -5%;
  }
  
} 


